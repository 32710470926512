<template>
  <section>
    <b-button variant="primary" @click="beforeShowSide(true)" v-if="lists.length === 0 && loaded">{{$t('lists.createlist')}}</b-button>
    <div class="d-flex justify-content-center flex-wrap" v-if="show_empty_state">
      <div class="col-12">
        <b-img :src="list_empty_state" class="img-empty-list"></b-img>
      </div>
    </div>
    <div v-else-if="loaded">
      <b-list-group
        class="col-12 col-lg-2 lists-group float-left mb-2"
      >
        <div class="px-2 pb-1">
          <span>
            {{$t('dashboard.list')}}
          </span>
          <b-tooltip target="add_list_tooltip" placement="top">{{$t('lists.createlist')}}</b-tooltip>
          <feather-icon id="add_list_tooltip" icon="PlusIcon" class="float-right cursor-pointer" @click="beforeShowSide(true)"></feather-icon>
        </div>
        <draggable
          v-model="lists"
          tag="div"
          @update="reorder"
        >
          <b-list-group-item v-for="(list, index) in lists" :key="index" @click="changeList(list)" class="lists-item-list" :ref="`list_${list.uuid}`">
            <div class="font-weight-bold title-list-height" ref="title_tab">
            <span>
              <feather-icon icon="ListIcon" class="mr-1 cursor-move"></feather-icon>
              {{ list.title }}
            </span>
          </div> 
          </b-list-group-item>
        </draggable>
      </b-list-group>
      <list-detail
        v-if="Object.keys(list).length > 0"
        class="col-12 col-lg-10 float-right"
        :list="list"
        :processing="lists.map((item) => item.processing)"
        :key="update_list"
        @delete_list="deleteList"
        @edit_list="editList"
        @update_tabs="updateTabs"
      ></list-detail>
    </div>
    <side-add-list
      :show_modal="show_side_add" 
      :key="change_side_add"
      @update_add_list="updateTabs"
      :is_adding="is_adding"
      :list_dad="list_to_edit"
    ></side-add-list>
  </section>
</template>

<script>
import service from "@/services/lists";
import ListDetail from './ListDetail.vue';
import SideAddList from './SideAddList.vue';
import utils from '@/libs/utils';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from 'vuedraggable';

import { 
  BListGroup,
  BListGroupItem,
  BImg,
  BTooltip,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Lists',
  components: {
    ListDetail,
    SideAddList,
    BListGroup,
    BListGroupItem,
    BImg,
    BTooltip,
    draggable,
    BButton,
  },
  data() {
    return {
      utils,
      list: {},
      lists: [],
      update_list: false,
      show_side_add: false,
      change_side_add: 0,
      is_adding: false,
      list_to_edit: undefined,
      list_empty_state: require('@/assets/images/elements/campaign/coleccion.png'),
      show_empty_state: false,
      loaded: false,
    }
  },
  created() {
    this.$vs.loading()
    this.getLists();
    setTimeout(() => {
      this.$refs.title_tab.clientWidth < this.$refs.title_tab.scrollWidth || 20 < this.$refs.title_tab.scrollHeight;
    }, 50);
  },
  methods: {
    reorder() {
      this.clearActiveList();
      this.$refs[`list_${this.list.uuid}`][0].classList.add('lists-item-list-active');
      const new_order = []
      this.lists.forEach((list, index) => {
        const obj = {
          'uuid': list.uuid,
          'order': index
        }
        new_order.push(obj)
      })

      service.orderLists(new_order)
        .then(() => {
        })
    },
    editList(list) {
      this.beforeShowSide(false, list);
    },
    orderLists(lists) {
      lists.sort(function(a, b) {
        return a.order - b.order;
      });

      return lists
    },
    getLists(list = {}) {
      return service.getLists().then((response) => {
        const lists_ordered = this.orderLists(response.results)
        this.lists = lists_ordered;
        this.update_list = !this.update_list;
        let selected_list = 0;
        if (Object.keys(list).length > 0) {
          this.lists.forEach((item) => {
            if (list.uuid === item.uuid) selected_list = item;
          });
        } else {
          selected_list = this.lists[0];
        }
        this.list = selected_list
        this.show_empty_state = !this.lists.length > 0;
        this.loaded = true;
        setTimeout(() => {
          this.$vs.loading.close();
          this.clearActiveList();
          this.$refs[`list_${selected_list.uuid}`][0].classList.add('lists-item-list-active');
        }, 500);
      });
    },
    updateTabs(list, is_adding) {
      if (is_adding) {
        this.getLists(list);
      } else if (is_adding === false) {
        const new_list = [];
        this.lists.map((item) => {
          if (item.uuid === list.uuid) item = list;
          if (item.uuid === this.list.uuid) this.list = list
          new_list.push(item);
        })
        const lists = this.orderLists(new_list)
        this.lists = lists;
      }
    },
    beforeShowSide(is_adding, list) {
      this.show_side_add = true; 
      this.change_side_add += 1;
      this.is_adding = is_adding;
      this.list_to_edit = list;
    },
    disableDeleteList() {
      return !utils.hasPermission('delete_list');
    },
    changeList(list) {
      this.list = list;
      this.update_list = !this.update_list;
      this.clearActiveList();
      this.$refs[`list_${list.uuid}`][0].classList.add('lists-item-list-active');
    },
    clearActiveList() {
      this.lists.forEach(list => {
        this.$refs[`list_${list.uuid}`][0].classList.remove('lists-item-list-active');
      })
    },
    deleteList(list) {
      this.$swal({
        title: this.$t('lists.delete_confirm'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('lists.delete_confirm_button'),
        reverseButtons: true,
        heightAuto: false,
      }).then((result) => {
        if (result.value) {
          if (!this.disableDeleteList()) {
            service.deleteList(list.uuid).then(() => {
              this.getLists();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('lists.deletelist'),
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: this.$t('lists.deletedata')
                }
              });
            });
          }

        }
      });
    },
  },
}
</script>

<style>
.title-list-height{
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.img-empty-list {
  width: 60%;
  display: block;
  margin: 0 auto;
}
.lists-group {
  background-color: white;
  height: auto !important;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 2em 0 0 0;
  position: relative;
}
.lists-item-list {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.lists-item-list:hover {
  background-color: #EFF3F6 !important;
}
.lists-item-list-active {
  background-color: #EFF3F6;
}
</style>