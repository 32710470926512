<template>
  <section class="animation-tab-right">

    <canvas-color 
      :image="utils.getAssetUrl(list.cover)" 
      v-if="list.color_palette === null"
      @color_palette="updateListColorPalette"
    ></canvas-color>
    <b-card class="mb-5 card-name-list" :style="(list.color_palette !== null) ? getBackgroundColorPalette() : ''">
      <div class="d-flex justify-content-center justify-content-md-start flex-wrap flex-md-nowrap">
        <b-avatar 
          :src="utils.getAssetUrl(list.cover)"
          square
          size="240px"
          class="d-block avatar-list-cover mb-1"
        ></b-avatar>
        <div class="ml-2 d-flex flex-column justify-content-between text-shadow-contrast">
          <div class="text-shadow-contrast">
            <span class="d-block">{{utils.getDatev2(new Date(list.updated_at))}} {{utils.getHourMinutes(list.updated_at)}}</span>
            <span class="main-title-list">
              {{list.title ? list.title.substr(0, 24) : ''}}
            </span>
          </div>
          <div v-if="list.description">
            {{list.description.substr(0, 400)}}
          </div>
        </div>
        <feather-icon
          icon="XIcon"
          size="20"
          class="remove-list-icon"
          id="delete_list_tooltip"
          @click="$emit('delete_list', list)"
        />
        <feather-icon
          icon="EditIcon"
          size="20"
          class="edit-list-icon"
          id="edit_list_tooltip"
          @click="$emit('edit_list', list)"
        />
        <b-tooltip target="delete_list_tooltip" placement="top">{{$t('lists.delete_list')}}</b-tooltip>
        <b-tooltip target="edit_list_tooltip" placement="bottom">{{$t('lists.editList')}}</b-tooltip>
      </div>
    </b-card>

    <div class="mb-1 d-flex justify-content-center justify-content-md-end flex-wrap actions-dropdown-list">
      <b-button  
        @click="show_side_influencer = true; has_changed_influencer = !has_changed_influencer"
        variant="flat-secondary"
        class="mb-1"
      >
        <feather-icon icon="UserPlusIcon" />
        <span class="ml-1">{{ $t("lists.addInfluencer") }}</span>
      </b-button>
      <b-button variant="flat-secondary" id="refresh-icon-list" class="refresh-icon-list-button mb-1" @click="updateInfluencers">
        <feather-icon icon="RefreshCcwIcon" class="refresh-icon-list cursor-pointer" size="17"></feather-icon>
      </b-button>
      <b-tooltip target="refresh-icon-list" custom-class="z-index-0" placement="top">
        {{$t('campaigns.refresh')}}
      </b-tooltip>

      <dropdown-campaigns 
        :campaigns="options_campaign" 
        :disabled="selected.length === 0" 
        :influencers="selected" 
        :multiple_added="true"
        class="mr-1 mb-1 dropdown-campaigns-lists"
      />
      <b-dropdown
        id="dropdown-right"
        :text="$t('campaigns.actions')"
        variant="flat-secondary"
        right
        class="mb-1"
      >
        <b-dropdown-item @click="checkProcessing" :disabled="list.processing">
          <feather-icon icon="ShareIcon"></feather-icon>
          <span class="mx-1">{{ $t("lists.addListCsv") }}</span>
          <b-spinner class="spinner-upload-csv" v-if="list.processing"></b-spinner>
        </b-dropdown-item>
        <b-dropdown-item :disabled="selected.length === 0" @click="exportSelected">
          <feather-icon icon="DownloadIcon"></feather-icon>
          <span class="ml-1"> {{$t('lists.export_list_csv')}}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item :disabled="selected.length === 0" @click="deleteInfluencer()">
          <feather-icon icon="XCircleIcon" />
          <span class="ml-1">{{ $t("lists.delete_influencers") }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-card class="w-100">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: page_length,
        }"
        @on-selected-rows-change="selectionChanged"
        class="vgt-table-list"
      >
        <div slot="emptystate" class="mt-1">
          {{$t('lists.empty_state_influencers')}}
        </div>
        <template slot="table-column" slot-scope="props">
          <div v-if="props.column.field === 'username'" class="cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'network'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'followers'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <div v-if="props.column.field === 'categories'" class="d-block text-center cursor-pointer">
            {{ $t(props.column.label) }}
            <b-icon icon="chevron-bar-expand" class="float-right"/>
          </div>
          <span v-if="props.column.field === 'actions'" class="d-block text-center">
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'username'" class="text-nowrap row-selected-table ">
            <b-media vertical-align="center" class="d-flex align-items-center">
              <template #aside>
                <div class="container-avatar-table">
                  <b-avatar
                    size="md"
                    :src="getImage(props.row)"
                    :text="props.row.username[0]"
                    variant="light-primary"
                  />
                </div>
              </template>
                <b-link class="link-influencer" :href="`/perfil/${props.row.network}/${props.row.username}/`" target="_blank">{{ props.row.username }}
                  <image-verified v-if="props.row.is_verified"/>
                </b-link>
            </b-media>
          </span>
          <span
            v-else-if="props.column.field === 'network'"
            class="text-nowrap d-block text-center p-2"
          > 
            <a class="text-nowrap name-link" 
              :href="utils.getURL(props.row.network, props.row.username, (props.row.network === 'youtube') ? true : false)" 
              target="_blank"
            >
              <b-img class="icon-networks" :src="`${utils.getImageNetworkColor(props.row.network)}`"/>
              {{
                utils.capitalize(props.row.network)
              }}</a>
          </span>
          <span
            v-else-if="props.column.field === 'followers'"
            class="text-nowrap d-block text-center"
          > 
            <span class="text-nowrap">{{ utils.getFormat(props.row.followers) }}</span>
          </span>
          <span v-else-if="props.column.field === 'categories'" class="d-block text-center">
            <div v-for="(category, index) in props.row.categories" :key="index">
              <b-badge pill variant="light-secondary-v2" class="light-secondary-v2" v-if="utils.checkNumber(category)">{{getNameCategory(parseInt(category), props.row.network)}}</b-badge>
              <b-badge pill variant="light-secondary-v2" class="light-secondary-v2" v-else>{{category}}</b-badge>
            </div>
          </span>
          <span v-else-if="props.column.field === 'actions'" class="d-block text-center">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                dropright
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="deleteInfluencer(props.row.uuid)">
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>{{$t('campaigns.delete_creator')}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('campaigns.showing_to')}} </span>
              <b-form-select
                v-model="page_length"
                :options="['50', '100', '150']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{$t('campaigns.of')}} {{ props.total }} {{$t('campaigns.entries')}}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="page_length"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) => props.pageChanged({ currentPage: value })
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <add-influencer
      :show_side_influencer="show_side_influencer"
      :list_service="true" 
      @add_influencer="addInfluencer"
      :key="has_changed_influencer"
    />

    <modal-brandme :show_modal="show_import_csv" :key="change_modal_csv">
      <template #title>
        {{$t('lists.addListText')}}
      </template>
      <template #body>
        <validation-observer ref="updateProfile" #default="{ invalid }">
          <b-link href="https://brandme.la/wp-content/template_listas.csv">
            <feather-icon icon="DownloadIcon" />
            {{ $t("lists.downloadTemplate") }}
          </b-link>
          <validation-provider rules="required">
            <b-form-file
              accept=".csv"
              class="mt-1"
              v-model="file_csv"
              :placeholder="$t('lists.addListText1')"
            ></b-form-file>
          </validation-provider>
          <b-button
            :disabled="invalid"
            variant="primary"
            class="my-1"
            @click="uploadCSV"
          >
          {{$t('lists.upload')}}
          </b-button>
        </validation-observer>
      </template>
    </modal-brandme>
  </section>
</template>

<script>
import utils from "@/libs/utils";
import { VueGoodTable } from "vue-good-table";
import service from "@/services/lists";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddInfluencer from '@/views/campaings/influencerSearch.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ModalBrandme from '@/views/components/modal/ModalBrandme.vue';
import service_get_campaigns from '@/services/dashboard';
import ImageVerified from '@/views/components/image/ImageVerified.vue';
import dropdownCampaigns from "@/views/campaings/dropdownCampaigns.vue";
import CanvasColor from "./CanvasColor.vue";

import { 
  BCard, 
  BAvatar, 
  BButton, 
  BPagination, 
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BMedia,
  BImg,
  BFormFile,
  BLink,
  BDropdownDivider,
  BSpinner,
  BTooltip,
  BBadge,
} from 'bootstrap-vue';

export default {
  name: "ListDetail",
  components: {
    ImageVerified,
    BCard,
    BAvatar,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BMedia,
    BImg,
    AddInfluencer,
    ValidationObserver,
    ValidationProvider,
    BFormFile,
    BLink,
    BDropdownDivider,
    ModalBrandme,
    BSpinner,
    BTooltip,
    BBadge,
    dropdownCampaigns,
    CanvasColor,
  },
  data() {
    return {
      options_campaign: [],
      rute_csv_img: require('@/assets/images/elements/campaign/filecsv.svg'),
      utils,
      show_side_influencer: false,
      columns: [
        {
          label: "lists.influencer",
          field: "username",
        },
        {
          label: "settings.social.network",
          field: "network",
        },
        {
          label: "campaigns.followers",
          field: "followers",
          type: 'number'
        },
        {
          label: "search.categories",
          field: "categories",
        },
        {
          label: "campaigns.actions",
          field: "actions",
        },
      ],    
      rows: [],
      page_length: 50,
      file_csv: null,
      has_changed_influencer: false,
      selected: [],
      show_import_csv: false,
      change_modal_csv: 1.1,
      random_number: (this.list.color_palette !== null) ? Math.floor(Math.random() * this.list.color_palette.length) : 0,
    };
  },
  props: {
    list: {
      type: Object,
      required: true
    },
    processing: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.getInfluencers();
    this.getCampaigns();
    setTimeout(() => {
      
    }, 200);
  },
  methods: {
    updateListColorPalette(palette) {
      const form_data = new FormData();
      form_data.append('title', this.list.title);
      form_data.append('color_palette', JSON.stringify(palette));
      service.updateList(form_data, this.list.uuid).then((response) => {
        this.$emit('update_tabs', response, false);
      });
    },
    getBackgroundColorPalette() {
      const string = `background: linear-gradient(0deg, #000000e8, 25%, ${this.list.color_palette[this.random_number]} 100%);
              text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
              color: white;`
      return string;
    },
    getNameCategory(category, network) {
      let entry = false
      switch (network) {
      case 'instagram':
        entry = this.$t('instaCategories').find((e) => e.value === category);
        if (entry) return entry.text
        break;
      case 'youtube':
        entry = this.$t('youtubeCategory').find((e) => e.value === category);
        if (entry) return entry.text
        break;

      case 'tiktok':
        entry = this.$t('tiktokCategory').find((e) => e.value === category);
        if (entry) return entry.text
        break;
      }

      return ''
    },
    updateInfluencers() {
      this.getInfluencers()
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: this.$t('lists.list_updated'),
          icon: "CoffeeIcon",
          variant: "success",
          text: this.$t('lists.list_text')
        }
      });
    },
    getInfluencers() {
      if (this.list.uuid) {
        service.getInfluencers(this.list.uuid).then((response) => {
          this.rows = response.results;
        });
      }
    },
    getCampaigns() {
      service_get_campaigns.getCampaign().then((res) => {
        res.response.results.filter((item) => {
          const obj = {value:item.uuid, text: item.name};
          this.options_campaign.push(obj);
        });
      });
    },
    checkProcessing() {
      if (this.processing.filter((item) => item === true).length > 0) {
        this.$swal({
          title: this.$t('lists.titleCsvProcess'),
          text: this.$t('lists.already_processing'),
          variant: 'danger',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger'
          },
          buttonsStyling: false,
          heightAuto: false,
        });
      } else {
        this.show_import_csv = true;
        this.change_modal_csv += 0.1;
      }
    },
    getImage(influencer) {
      if (influencer.image)
        return influencer.image;
      else 
        return influencer.profile_url;
    },
    addInfluencer(formData) {
      service.bulkAddInfluencer(this.list.uuid, formData).then(() => {
        this.show_side_influencer = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-left",
          props: {
            title:  this.$t('lists.addinfluencer'),
            icon: "CoffeeIcon",
            variant: "success",
            text: this.$t('lists.addlist')
          }
        });
        this.getInfluencers();
      })
    },
    uploadCSV() {
      const formData = new FormData();
      formData.set("file_csv", this.file_csv);
      service.addListExcel(this.list.uuid, formData).then((res) => {
        if (res.results) {
          this.show_import_csv = false;
          this.change_modal_csv += 0.1;
          this.list.processing = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('lists.addSuccess'),
              icon: "BellIcon",
              text: this.$t('lists.addSuccessText'),
              variant: "success",
            }
          },
          {
            timeout: 20000  
          });
          setTimeout(() => {
            this.rows.push(res.results);
          }, 5000)
        } else if (res.code && res.code === 'csv.BadFormat') {
          this.$swal({
            title: this.$t('lists.errorCsv'),
            text:  this.$t('lists.formatCsv'),
            imageUrl: require('@/assets/images/lists/example_csv.png'),
            imageWidth: 400,
            imageHeight: 200,
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            heightAuto: false,
          })
        } else if (res.code && res.code === 'list.Processing') {
          this.$swal({
            title: this.$t('lists.titleCsvProcess'),
            text: this.$t('lists.textCvsProcess'),
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            heightAuto: false,
          })
        }
      });
    },
    deleteInfluencer (uuid = false) {
      const formData = {
        influencers: uuid !== false ? [uuid] : this.selected.map((item) => item.uuid)
      };
      service.bulkRemoveInfluencer(this.list.uuid, formData).then(() => {
        if (uuid !== false) {
          this.rows = this.rows.filter((item) => item.uuid !== uuid);
        } else {
          this.selected.map((item) => {
            this.rows = this.rows.filter((i) => i.uuid !== item.uuid);
          })
        }
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('lists.delInfluencer'),
            icon: "CoffeeIcon",
            variant: "success",
            text: this.$t('lists.dellist')
          }
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('lists.errordelete'),
            icon: "CoffeeIcon",
            variant: "danger"
          }
        })
      })
    },
    selectionChanged(selected) {
      this.selected = selected.selectedRows;
    },
    exportSelected() {
      const rows = [['Influencer', 'Red social', 'Seguidores']];
      this.selected.forEach(data => {

        rows.push([
          data.username,
          utils.capitalize(data.network),
          data.followers
        ]);
      });
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = "Influencers_lista.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
};
</script>

<style>
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
.main-title-list {
  font-size: 64px;
  font-weight: bold;
}
.link-influencer{
  color: black;
}  
.icon-networks{
  width: 20px !important;
} 
.actions-dropdown-list {
  margin-top: -3.2em;
}
.vgt-table-list {
  overflow-x: scroll;
}
.vgt-table-list table {
  width: 100% !important;
}
.avatar-list-detail {
  height: 200px;
  width: 200px;
}
.container-avatar-table {
  position: relative;
}
.container-avatar-table-icon {
  position: absolute; 
  bottom: -5px; 
  right: -15px;
}
.style-border-icon {
  font-size:1em; 
  border-radius: 50%; 
  background: white; 
  padding: 2px
}
.icon-check {
  height: 18px;
}
.w-list-sidebar .b-sidebar-right{
  width: 500px;
}
.animation-tab-right {
  animation: animate 1s;
}
.spinner-upload-csv {
  width: 1.7em;
  height: 1.7em;
  color: rgb(167, 167, 167);
}
.refresh-icon-list {
  color: #8B8F93 !important;
  margin: 0px;
}
.refresh-icon-list-button {
  margin: 0 1em;
  margin-top: 0px;
  padding: 0.5em;
}
.remove-list-icon {
  display: block;
  color: white;
  margin: 1.3em 1.3em;
  position: absolute;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  right: 0px;
  top: 0px;
}
.remove-list-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.edit-list-icon {
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  position: absolute;
  top: 35px;
  right: 0px;
  margin: 1.3em 1.3em;
  cursor: pointer;
}
.vgt-table th > span, .vgt-table th > div{ 
  color: black !important;
  padding-bottom: 1em;
}
@media (max-width: 991px) {
  .actions-dropdown-list {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .main-title-list {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .main-title-list {
    display: block;
    margin-top: 1em;
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .main-title-list {
    display: block;
    margin-top: 1em;
    font-size: 20px;
  }
}

@keyframes animate {
  0% {
    transform: translateX(800px);
  }
  100% {
    transform: translateX(0px);
  }
  
}
</style>
